import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { Stack } from '../../common/layouts/Stack'
import { PointyChevron } from '../../../assets/images/icons/pointyChevron'
import * as Styles from './styles'
import { BLUE_BASE, WHITE } from '../../../theme/variables/colors'
import { useScrollIntoView } from '../../../utils/hooks/useScrollIntoView'

export const CategoriesTab = ({ onClose, selectedId }) => {
  const insights = useStaticQuery(graphql`
    query {
      allContentstackInsightCategory(sort: { title: ASC }) {
        nodes {
          id
          url
          title
        }
      }
      allContentstackInsightArticle {
        group(field: { insight_categories: { id: SELECT } }) {
          fieldValue
          totalCount
        }
      }
    }
  `)

  const { elementRef } = useScrollIntoView()

  const categoryWithNumInsights = useMemo(
    () =>
      insights.allContentstackInsightCategory?.nodes
        ?.map(({ id, title, url }) => ({
          id,
          title,
          url,
          selected: id === selectedId,
          numInsights: insights.allContentstackInsightArticle.group?.find(
            (group) => group?.fieldValue === id
          )?.totalCount,
        }))
        .filter((insight) => insight.numInsights > 0),
    [insights]
  )

  return (
    <Grid container spacing={2} style={{ margin: 0, width: '100%' }}>
      {categoryWithNumInsights?.map(
        ({ title, numInsights, url, selected }, index) => (
          <Grid item key={index} xs={12} sm={6}>
            <Styles.Link
              to={url}
              onClick={onClose}
              selected={selected}
              ref={selected ? elementRef : undefined}
            >
              <Stack columnGap="10px">
                {title}
                <Styles.NumberOfArticles>
                  [{numInsights}]
                </Styles.NumberOfArticles>
              </Stack>
              <PointyChevron fill={selected ? WHITE : BLUE_BASE} />
            </Styles.Link>
          </Grid>
        )
      )}
    </Grid>
  )
}
