import React, { createContext, useContext, useState, useMemo } from 'react'
import { AttestationModal } from '../../components/attestationModal'
import { InsightsFiltersModal } from '../../components/insightsFiltersModal'
import FormModal from '../../components/Modal/formModal'

export const MODAL_TYPE = {
  ATTESTATION: 'ATTESTATION',
  INSIGHTS_FILTER: 'INSIGHTS_FILTER',
  FORM: 'FORM',
}

const modalTypeComponent = {
  [MODAL_TYPE.ATTESTATION]: AttestationModal,
  [MODAL_TYPE.INSIGHTS_FILTER]: InsightsFiltersModal,
  [MODAL_TYPE.FORM]: FormModal,
}

const ModalContext = createContext()

export const useModal = () => {
  return useContext(ModalContext)
}

export const ModalProvider = ({ children }) => {
  const [modalConfig, setModalConfig] = useState(null)

  const openModal = (modalType, props = {}) =>
    setModalConfig({ modalType, props })
  const closeModal = () => setModalConfig(null)

  const ModalComponent = useMemo(
    () => modalTypeComponent[modalConfig?.modalType],
    [modalConfig]
  )

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {ModalComponent && <ModalComponent {...modalConfig?.props} />}
      {children}
    </ModalContext.Provider>
  )
}
