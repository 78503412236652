import React, { useCallback, useReducer, useEffect } from 'react'

const replaceUrlDomainWithEnvSpecific = (url) => {
  if (typeof window !== 'undefined') {
    const { hostname } = window.location
    const replaceDomain = process.env.GATSBY_GOOGLE_SEARCH_REPLACE_DOMAIN

    if (
      replaceDomain &&
      (hostname.includes('uat.harborcapital') ||
        hostname.includes('dev.harborcapital'))
    ) {
      return url.replace('https://www.harborcapital', replaceDomain)
    }
  }
  return url
}

const SearchState = {
  results: [],
  status: 'idle',
  search: () => {},
  searchTerms: '',
}

const Context = React.createContext(SearchState)

const matches = (str1, str2) => {
  return (
    typeof str1 === 'string' &&
    typeof str2 === 'string' &&
    str1.toLocaleLowerCase().indexOf(str2.toLocaleLowerCase()) !== -1
  )
}

const CreateSearchProvider =
  (searchFn) =>
  ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
      switch (action.type) {
        case 'search::start':
          return { ...state, status: 'loading', searchTerms: action.payload }
        case 'search::finish':
          return { ...state, status: 'idle', results: action.payload }
        default:
          return state
      }
    }, SearchState)

    const search = useCallback((searchTerms) => {
      dispatch({ type: 'search::start', payload: searchTerms })
      ;(async () => {
        let results = []
        try {
          results = await searchFn(searchTerms)
        } finally {
          dispatch({ type: 'search::finish', payload: results })
        }
      })()
    }, mockData)

    useEffect(() => {
      if (window.location.pathname === '/search-results/') {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const params = Object.fromEntries(urlSearchParams.entries())
        search(params.q)
      }
    }, [])

    return (
      <Context.Provider value={{ ...state, search }}>
        {children}
      </Context.Provider>
    )
  }

const mockData = []

const MockupProvider = CreateSearchProvider(
  (searchTerms) =>
    new Promise((resolve) => {
      const results = []
      for (const record of mockData) {
        if (
          Object.values(record).find((value) => matches(value, searchTerms))
        ) {
          results.push(record)
        }
      }
      setTimeout(() => {
        resolve(results)
      }, 1000)
    })
)

const GoogleProvider = CreateSearchProvider(async (searchTerms) => {
  const quotaUser = Math.random()

  if (!searchTerms) {
    return []
  }

  const results =
    (
      await (
        await fetch(
          `https://www.googleapis.com/customsearch/v1/siterestrict?key=${process.env.GATSBY_GOOGLE_SEARCH_KEY}&cx=${process.env.GATSBY_GOOGLE_SEARCH_CX}&q=${searchTerms}&quotaUser=${quotaUser}`
        )
      ).json()
    ).items || []

  const replacedDomains = results.map((result) => {
    return {
      ...result,
      link: replaceUrlDomainWithEnvSpecific(result.link),
    }
  })

  return replacedDomains || []
})

const SearchContext = {
  Context,
  MockupProvider,
  GoogleProvider,
}

export default SearchContext
