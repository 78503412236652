import { useMediaQuery } from '@material-ui/core'
import React, { useContext, createContext, useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { COMMON_MOBILE_BREAKPOINT, USER_EXPERIENCES } from '../constants'
import { useAuthContext } from './AuthContext'

export const ContactUsCollapseContext = createContext()

export const useContactUsCollapseContext = () => {
  return useContext(ContactUsCollapseContext)
}

const ContactUsCollapseContexProvider = ({ children }) => {
  const isMobile = useMediaQuery(COMMON_MOBILE_BREAKPOINT)
  const [isCollpased, setIsCollapsed] = useState(false)
  const { pathname } = useLocation()

  const auth = useAuthContext()

  useEffect(() => {
    if (
      isMobile ||
      auth?.state?.userExperience === USER_EXPERIENCES.INSTITUTIONAL_ONLY
    ) {
      setIsCollapsed(true)

      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('contactUsCollapse', true)
      }
    } else if (typeof window !== 'undefined') {
      const sessionStorageIsCollapsed =
        window.sessionStorage.getItem('contactUsCollapse')

      if (sessionStorageIsCollapsed) {
        setIsCollapsed(sessionStorageIsCollapsed === 'true')
      }
    }
  }, [isMobile, auth, pathname])

  const handleSetIsCollapsed = () => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('contactUsCollapse', !isCollpased)
    }

    setIsCollapsed(!isCollpased)
  }

  return (
    <ContactUsCollapseContext.Provider
      value={[isCollpased, handleSetIsCollapsed]}
    >
      {children}
    </ContactUsCollapseContext.Provider>
  )
}

export default ContactUsCollapseContexProvider
