import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import { Link } from 'gatsby'
import { BLUE_BASE, GREEN, WHITE } from '../../theme/variables/colors'

export const Root = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${WHITE};
  border-radius: 8px;
  padding: 20px;
  color: ${BLUE_BASE};
  max-width: 1080px;
  width: 90vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  z-index: 1;
`
export const StyledCloseIcon = styled(CloseIcon)`
  color: inherit;
  cursor: pointer;
  font-size: 32px !important;
`
export const ResetFilter = styled(Link)`
  color: inherit;
  font-size: 12px;
  text-decoration: none;
  font-weight: 700;
`

const FILTER_CATEGORY_COLOR = {
  Author: GREEN,
  Category: '#C9317D',
  Product: '#4797CD',
}

export const Tab = styled.div`
  padding: 8px 12px;
  font-size: 14px;
  color: ${({ selected }) => (selected ? WHITE : BLUE_BASE)};
  background-color: ${({ selected }) => (selected ? BLUE_BASE : '#D4DBDF')};
  line-height: normal;
  cursor: pointer;
  border-radius: 4px 4px 0px 0px;
  font-weight: 700;
  border-bottom: ${({ filterCategory }) =>
    `4px solid ${FILTER_CATEGORY_COLOR[filterCategory]}`};
`
export const Content = styled.div`
  background-color: ${WHITE};
  color: ${BLUE_BASE};
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  flex: 1;
  min-height: 0;
`

export const Caption = styled.span`
  margin-top: 10px;
`

export const ResetFilterContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  height: 100%;
  column-gap: 28px;
  align-items: center;
  flex-direction: row;
  border-bottom: 2px solid ${BLUE_BASE};
`
