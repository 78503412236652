import styled from 'styled-components'

export const Box = styled.div`
  flex-direction: ${({ flexDirection }) => flexDirection};
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  column-gap: ${({ columnGap }) => columnGap};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  border: ${({ border }) => border};
  padding: ${({ padding }) => padding};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ width }) => width};
  color: ${({ color }) => color};
  overflow: ${({ overflow }) => overflow};
  overflow-y: ${({ overflowY }) => overflowY};
  align-self: ${({ alignSelf }) => alignSelf};
  cursor: ${({ cursor }) => cursor};
  max-height: ${({ maxHeight }) => maxHeight};
  line-height: ${({ lineHeight }) => lineHeight};
`
