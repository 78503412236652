import React, { useRef, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import PropTypes from 'prop-types'

const CustomRecaptcha = React.memo(
  ({
    gatsbyRecaptchaKey = '',
    onRecaptchaChange,
    onRecaptchaExpire,
    formSubmitted,
  }) => {
    const recaptchaRef = useRef(null)

    useEffect(() => {
      if (formSubmitted && recaptchaRef.current) {
        recaptchaRef.current.reset()
        onRecaptchaChange('')
      }
    }, [formSubmitted, onRecaptchaChange])

    const handleRecaptchaChange = (response, event) => {
      onRecaptchaChange(response)
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    const handleRecaptchaExpire = (event) => {
      onRecaptchaExpire()
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    return (
      <>
        {gatsbyRecaptchaKey && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={gatsbyRecaptchaKey}
            onChange={handleRecaptchaChange}
            onExpired={handleRecaptchaExpire}
          />
        )}
      </>
    )
  }
)

CustomRecaptcha.propTypes = {
  gatsbyRecaptchaKey: PropTypes.string,
  onRecaptchaChange: PropTypes.func.isRequired,
  onRecaptchaExpire: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
}

export default CustomRecaptcha
