import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import FormField from './FormField'

const FormRenderer = React.memo(
  ({
    formHTML,
    formValues,
    handleInputChange,
    fieldErrors,
    fieldRefs,
    validationRules,
  }) => {
    const transformFunction = useCallback(
      (node, index) => {
        if (node.type === 'tag') {
          const { name } = node

          if (
            ['input', 'select', 'textarea', 'label', 'button'].includes(name)
          ) {
            return (
              <FormField
                key={index}
                node={node}
                formValues={formValues}
                handleInputChange={handleInputChange}
                fieldErrors={fieldErrors}
                fieldRefs={fieldRefs}
                validationRules={validationRules}
              />
            )
          }
        }
        return undefined
      },
      [formValues, handleInputChange, fieldErrors, fieldRefs, validationRules]
    )

    return <>{parse(formHTML, { replace: transformFunction })}</>
  }
)

FormRenderer.propTypes = {
  formHTML: PropTypes.string.isRequired,
  formValues: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  fieldRefs: PropTypes.object.isRequired,
  validationRules: PropTypes.object.isRequired,
}

export default FormRenderer
