import styled from 'styled-components'

export const FormContainer = styled.div`
  width: 100%;
`
export const FormBody = styled.form`
  width: 100%;
`
export const FormSuccess = styled.p`
  color: rgb(9, 42, 72);
  margin-bottom: 20px;
`
export const GetHelp = styled.p`
  color: red;
  margin: 20px 0;

  a {
    color: rgb(9, 42, 72);
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const FormError = styled.p`
  color: red;
`

export const SubmitButton = styled.button`
  background-color: rgb(9, 42, 72);
  border: none;
  font-size: 14px;
  border-radius: 25px;
  padding: 10px 20px;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 40px;

  &:disabled {
    background-color: rgb(221, 229, 237);
    color: rgb(9, 72, 42);
    cursor: not-allowed;
  }
`
