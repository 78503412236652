import React, { startTransition, useCallback } from 'react'
import PropTypes from 'prop-types'
import CustomRecaptcha from '../Recaptcha/Recaptcha'

const RecaptchaWrapper = ({
  formId,
  gatsbyRecaptchaKey,
  onRecaptchaChange,
  onRecaptchaExpire,
  formSubmitted,
}) => {
  const uniqueRecaptchaId = `${formId}_recaptcha`

  const handleRecaptchaChange = useCallback(
    (value) => {
      startTransition(() => {
        onRecaptchaChange(value)
      })
    },
    [onRecaptchaChange]
  )

  const handleRecaptchaExpire = useCallback(() => {
    startTransition(() => {
      onRecaptchaExpire()
    })
  }, [onRecaptchaExpire])

  return (
    <div id={`recaptcha-container-${formId}`} style={{ marginTop: '1rem' }}>
      <CustomRecaptcha
        id={uniqueRecaptchaId}
        onRecaptchaChange={handleRecaptchaChange}
        onRecaptchaExpire={handleRecaptchaExpire}
        gatsbyRecaptchaKey={gatsbyRecaptchaKey}
        formSubmitted={formSubmitted}
      />
    </div>
  )
}

RecaptchaWrapper.propTypes = {
  formId: PropTypes.string.isRequired,
  gatsbyRecaptchaKey: PropTypes.string.isRequired,
  onRecaptchaChange: PropTypes.func.isRequired,
  onRecaptchaExpire: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
}

export default RecaptchaWrapper
