import React from 'react'
import { Helmet } from 'react-helmet'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import theme from '../../src/theme'
import ContactUsCollapseContexProvider from '../../src/utils/context/ContactUsCollapseContext'

export default function TopLayout({ children }) {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Helmet>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <ContactUsCollapseContexProvider>
            {children}
          </ContactUsCollapseContexProvider>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </>
  )
}
