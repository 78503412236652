import PropTypes from 'prop-types'
import React from 'react'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import CloseImg from 'src/images/close-icon.svg'
import HarborLogo from 'src/images/HFLogoOnlyDark.svg'

export const S = {
  Body: styled.div`
    background: white;
    border-radius: 32px;
    max-width: 552px;
    padding: 32px 72px;
    position: relative;
    max-height: 100vh;
    @media (max-width: 960px) {
      max-width: 95vw;
      padding: 32px 5%;
    }
    @media (max-height: 680px) {
      max-width: 678px;
      padding: 15px 72px;
    }
  `,
  Content: styled(Modal.Content)`
    padding: 30px 0 60px 0;
    @media (max-height: 680px) {
      padding: 0 0 60px 0;
    }
  `,
  Header: styled(Modal.Header)`
    text-align: center;
  `,
  Modal: styled.div`
    align-items: center;
    background: #0005;
    backdrop-filter: blur(10px);
    display: ${(props) => (props.open ? 'flex' : 'none')};
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${(props) => (props.open ? '9999' : '-1')};
  `,
  Logo: styled.img`
    width: 175px;
    object-fit: contain;
  `,
  CloseIcon: styled.img`
    top: 35px;
    right: 35px;
    position: absolute;
    width: 18px;
    cursor: pointer;
  `,
}

export function HarborModal({
  closeable = true,
  children,
  buttonActions,
  onClose,
  open = false,
  logo,
  withLogo = true,
  ...props
}) {
  return (
    <S.Modal open={open} {...props}>
      <S.Body>
        {closeable && <S.CloseIcon src={CloseImg} onClick={onClose} />}
        <S.Header>{withLogo && (logo || <S.Logo src={HarborLogo} />)}</S.Header>
        <S.Content>{children}</S.Content>
        {buttonActions && <Modal.Actions>{buttonActions}</Modal.Actions>}
      </S.Body>
    </S.Modal>
  )
}

HarborModal.propTypes = {
  closeable: PropTypes.bool,
  children: PropTypes.any,
  open: PropTypes.bool,
  buttonActions: PropTypes.arrayOf(PropTypes.element),
  title: PropTypes.string,
}
