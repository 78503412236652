import React, { useMemo, forwardRef } from 'react'
import { Grid } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { Stack } from '../../common/layouts/Stack'
import * as Styles from './styles'
import { PointyChevron } from '../../../assets/images/icons/pointyChevron'
import { GREEN, ORANGE } from '../../../theme/variables/colors'
import { useScrollIntoView } from '../../../utils/hooks'

const TickerTab = forwardRef(
  ({ title, ticker, onClose, selected, numInsights, productType }, ref) => (
    <Grid item xs={12} sm={6}>
      <Styles.Link
        to={`/insights/product/${ticker?.toLowerCase()}`}
        onClick={onClose}
        selected={selected}
        ref={ref}
      >
        <Stack flexDirection="column">
          <Stack columnGap="10px">
            {ticker}
            <Styles.NumberOfArticles>[{numInsights}]</Styles.NumberOfArticles>
          </Stack>
          <Styles.Title>{title}</Styles.Title>
        </Stack>
        <PointyChevron fill={productType === 'ETF' ? ORANGE : GREEN} />
      </Styles.Link>
    </Grid>
  )
)

export const ProductsTab = ({ onClose, selectedId }) => {
  const insights = useStaticQuery(graphql`
    query {
      allContentstackProductV2(sort: { fund_classes: { ticker: ASC } }) {
        nodes {
          id
          title
          product_type
          fund_classes {
            ticker
          }
        }
      }
      allContentstackInsightArticle {
        group(field: { related_products: { id: SELECT } }) {
          fieldValue
          totalCount
        }
      }
    }
  `)

  const { elementRef } = useScrollIntoView()

  const tickerWithNumInsights = useMemo(() =>
    insights.allContentstackProductV2?.nodes
      ?.map(({ id, fund_classes, product_type, title }) => ({
        id,
        title,
        selected: id === selectedId,
        productType: product_type,
        ticker: fund_classes?.[0]?.ticker,
        numInsights: insights.allContentstackInsightArticle.group?.find(
          (group) => group?.fieldValue === id
        )?.totalCount,
      }))
      .filter((insight) => insight.numInsights > 0)
  )

  const mutualFundsTickerWithNumInsights = useMemo(
    () =>
      tickerWithNumInsights.filter(
        (insight) => insight.productType === 'MUTUAL_FUND'
      ),
    [tickerWithNumInsights]
  )

  const etfTickerWithNumInsights = useMemo(
    () =>
      tickerWithNumInsights.filter((insight) => insight.productType === 'ETF'),
    [tickerWithNumInsights]
  )

  return (
    <>
      {etfTickerWithNumInsights.length > 0 && (
        <>
          <Styles.ProductTypeTitle>ETF</Styles.ProductTypeTitle>
          <Grid container spacing={2} style={{ margin: 0, width: '100%' }}>
            {etfTickerWithNumInsights.map(
              (
                { numInsights, selected, ticker, title, productType },
                index
              ) => (
                <TickerTab
                  key={index}
                  title={title}
                  ticker={ticker}
                  onClose={onClose}
                  selected={selected}
                  numInsights={numInsights}
                  productType={productType}
                  ref={selected ? elementRef : undefined}
                />
              )
            )}
          </Grid>
        </>
      )}
      {mutualFundsTickerWithNumInsights.length > 0 && (
        <>
          <Styles.ProductTypeTitle>MUTUAL FUNDS</Styles.ProductTypeTitle>
          <Grid container spacing={2} style={{ margin: 0, width: '100%' }}>
            {mutualFundsTickerWithNumInsights.map(
              (
                { numInsights, selected, ticker, title, productType },
                index
              ) => (
                <TickerTab
                  key={index}
                  title={title}
                  ticker={ticker}
                  onClose={onClose}
                  selected={selected}
                  numInsights={numInsights}
                  productType={productType}
                  ref={selected ? elementRef : undefined}
                />
              )
            )}
          </Grid>
        </>
      )}
    </>
  )
}
