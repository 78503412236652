import { useEffect, useState } from 'react'
import { useLocation } from '@gatsbyjs/reach-router'

const useQueryString = () => {
  const { search } = useLocation()

  const [queryParams, setQueryParams] = useState(new URLSearchParams(search))

  const getQueryParam = (param) => queryParams.get(param)

  const setQueryParam = (param, value) => {
    const newParams = new URLSearchParams(queryParams)
    newParams.set(param, value)

    // Assuming you want to replace the current URL without a full page reload
    window.history.replaceState({}, '', `?${newParams.toString()}`)
    setQueryParams(newParams)
  }

  useEffect(() => {
    setQueryParams(new URLSearchParams(search))
  }, [search])

  return {
    getQueryParam,
    setQueryParam,
  }
}

export default useQueryString
