
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #f3f3f3;
  height: 5px;
  margin-bottom: 20px;
  border-radius: 2.5px;
  overflow: hidden;
  position: relative;
`

const Progress = styled.div`
  height: 100%;
  background-color: #3498db;
  width: ${(props) => props.width}%;
  transition: width 0.4s ease;
`

const ProgressBar = ({ isSubmitting, onComplete }) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    let timer
    if (isSubmitting) {
      // Start simulating progress
      timer = setInterval(() => {
        setProgress((prev) => {
          const nextProgress = prev + Math.random() * 10
          if (nextProgress >= 80) {
            clearInterval(timer)
            return 80
          }
          return nextProgress
        })
      }, 500)
    } else {
      // Complete the progress
      setProgress(100)
      if (onComplete) {
        onComplete()
      }
    }

    return () => clearInterval(timer)
  }, [isSubmitting, onComplete])

  return (
    <ProgressBarContainer aria-hidden={progress === 0}>
      <Progress width={progress} />
    </ProgressBarContainer>
  )
}

ProgressBar.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onComplete: PropTypes.func,
}

ProgressBar.defaultProps = {
  onComplete: () => {},
}

export default ProgressBar
