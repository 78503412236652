/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import '@fontsource/poppins/latin-200.css'
import '@fontsource/poppins/latin-300.css'
import '@fontsource/poppins/latin-400.css'
import '@fontsource/poppins/latin-500.css'
import '@fontsource/poppins/latin-700.css'

import '@fontsource/roboto/latin-300.css'
import '@fontsource/roboto/latin-400.css'
import '@fontsource/roboto/latin-500.css'
import '@fontsource/roboto/latin-700.css'

import '@fontsource/ibm-plex-sans/latin-300.css'
import '@fontsource/ibm-plex-sans/latin-400.css'
import '@fontsource/ibm-plex-sans/latin-500.css'
import '@fontsource/ibm-plex-sans/latin-700.css'

import 'src/styles/global.scss'

// You can delete this file if you're not using it
import wrapRootElement from './src/wrap-root-element'

export const onPreRouteUpdate = ({ prevLocation }) => {
  if (prevLocation) {
    // Save the previous location for use in PrivateRoute component
    window.prevLocation = prevLocation
  }
}

export { wrapRootElement }
