import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import * as Styles from './formModal.styles'
import FormAssemblyForm from '../Form/FormAssemblyForm'

const FormModal = React.memo(
  ({
    onClose,
    isOpen,
    formId,
    customValidation,
    isModal,
    ctaText,
    fromLocation,
  }) => {
    const [isSpinning, setIsSpinning] = useState(false)

    const handleCloseClick = () => {
      setIsSpinning(true)
      setTimeout(() => {
        setIsSpinning(false)
        onClose()
      }, 400)
    }

    if (!formId || !isOpen) {
      return null
    }

    return ReactDOM.createPortal(
      <>
        <Styles.OuterModal>
          <Styles.HeaderModal>
            <Styles.CloseButton
              className={isSpinning ? 'spin' : ''}
              onClick={handleCloseClick}
            >
              <Styles.LineBefore />
              <Styles.LineAfter />
            </Styles.CloseButton>
          </Styles.HeaderModal>
          <Styles.BodyModal>
            <FormAssemblyForm
              formId={formId}
              customValidation={customValidation}
              isModal={isModal}
              ctaText={ctaText}
              fromLocation={fromLocation}
            />
          </Styles.BodyModal>
        </Styles.OuterModal>
      </>,
      document.getElementById('modal-root')
    )
  }
)

export default FormModal
