import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useTransition,
} from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Location } from '@reach/router'
import Cookies from 'js-cookie'
import { useCookies } from 'react-cookie'
import IframePopup from '../components/IframePopup'
import { AuthContext } from './context/AuthContext'
import { ONE_HOUR_COOKIE_EXPIRE, USER_EXPERIENCES } from './constants'
import { AttestationModal } from '../components/attestationModal'
import FormModal from '../components/Modal/formModal'
import { useToken } from './context/TokenContext'

function useSendUserExperienceGtm({ allRoutes, pathname, isInsight }) {
  useEffect(() => {
    const isWindowDefined = typeof window !== 'undefined'

    if (isWindowDefined && isInsight) {
      const onlyInsightsRoutes = [
        ...(allRoutes?.allContentstackInsightArticle?.nodes ?? []),
        ...(allRoutes?.allContentstackInsightWebinar?.nodes ?? []),
      ]

      const currentInsight = onlyInsightsRoutes.find(({ url }) =>
        pathname.includes(url)
      )

      const currentInsightExperience =
        currentInsight?.user_experience?.experience

      if (currentInsightExperience) {
        window.dataLayer.push({
          userExperience: currentInsightExperience,
        })
      }
    }
  }, [isInsight, pathname, allRoutes])
}

const skipLoginPaths = ['/contact-us/institutional']

const GatedContentHandler = ({ children, router }) => {
  const [openIframe, setIsOpenIframe] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [isPending, startTransition] = useTransition()
  const [isSignupViewed, setIsSignupViewed] = useState(false)

  const allRoutes = useStaticQuery(graphql`
    query {
      allContentstackContactUsLoggedIn(
        filter: {
          user_experience: { experience: { eq: "institutional-only" } }
        }
      ) {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackInsightArticle {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackInsightWebinar {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackLandingPage(
        filter: {
          user_experience: { experience: { eq: "institutional-only" } }
        }
      ) {
        nodes {
          url
          user_experience {
            ...UserExperience
          }
        }
      }
      allContentstackFaForm(filter: { form_id: { in: [29, 30] } }) {
        nodes {
          form_id
          form_cta_text
          custom_validations
        }
      }
    }
  `)

  const [cookies] = useCookies(['harborCookie'])
  const { harborCookie } = cookies

  const authCtx = useContext(AuthContext)
  const { csrfToken } = useToken()

  const { location } = router
  let { pathname, search, state } = location
  const { from: fromLocation } = state || {}

  const isInsight = useMemo(() => pathname.startsWith('/insights/'), [pathname])

  useSendUserExperienceGtm({ allRoutes, pathname, isInsight })

  const privatePages = useMemo(() => {
    // Exclude 'allContentstackFaForm' from the keys
    const routeKeys = Object.keys(allRoutes).filter(
      (key) => key !== 'allContentstackFaForm'
    )

    return routeKeys
      .flatMap((key) => allRoutes[key].nodes)
      .filter(
        (route) =>
          route.user_experience.experience ===
          USER_EXPERIENCES.INSTITUTIONAL_ONLY
      )
      .map((route) => route.url)
  }, [allRoutes])

  if (pathname.charAt(pathname.length - 1) === '/') {
    pathname = pathname.slice(0, -1)
  }

  const isPrivatePage = privatePages.includes(pathname)

  const queryParams = useMemo(() => new URLSearchParams(search), [search])
  const modalKey = useMemo(() => queryParams.get('form'), [queryParams])

  const faForms = allRoutes.allContentstackFaForm.nodes

  const formData = useMemo(() => {
    const mapping = {}
    faForms.forEach((form) => {
      switch (form.form_id) {
        case 29:
          mapping.login = {
            formId: form.form_id,
            formCta: form.form_cta_text,
            customValidation: form.custom_validations,
          }
          break
        case 30:
          mapping.register = {
            formId: form.form_id,
            formCta: form.form_cta_text,
            customValidation: form.custom_validations,
          }
          break
        default:
          break
      }
    })
    return mapping
  }, [faForms])

  const formId = useMemo(() => formData[modalKey]?.formId, [formData, modalKey])
  const formCta = useMemo(
    () => formData[modalKey]?.formCta,
    [formData, modalKey]
  )
  const customValidation = useMemo(
    () => formData[modalKey]?.customValidation,
    [formData, modalKey]
  )

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    if (isClient) {
      setIsSignupViewed(Cookies.get('signupViewed') === 'true')
    }
  }, [isClient])

  useEffect(() => {
    if (formId) {
      startTransition(() => {
        setModalOpen(true)
      })
    } else {
      startTransition(() => {
        setModalOpen(false)
      })
    }
  }, [formId, startTransition])

  const handleModalClose = () => {
    startTransition(() => {
      setModalOpen(false)
    })
    const updatedQueryParams = new URLSearchParams(router.location.search)
    updatedQueryParams.delete('form')
    const newSearch = updatedQueryParams.toString()

    const newUrl = router.location.pathname + (newSearch ? `?${newSearch}` : '')

    navigate(newUrl, { replace: true })
  }

  useEffect(() => {
    const fetchHarborCookie = async () => {
      try {
        const res = await fetch('/api/harbor-cookie', {
          headers: {
            'Content-Type': 'application/json',
            'x-csrf-token': csrfToken,
          },
          credentials: 'same-origin',
        })

        if (!res.ok) {
          throw new Error('Failed to fetch harbor-cookie')
        }

        const data = await res.json()

        if (!data) {
          Cookies.set('signupViewed', 'true', {
            expires: ONE_HOUR_COOKIE_EXPIRE,
          })
          window.dataLayer.push({
            signUpPopupViewed: 'User reached 10 articles viewed',
          })
          startTransition(() => {
            setIsOpenIframe(true)
          })
        } else {
          authCtx.actions.tryLogin()
        }
      } catch (error) {
        console.error('Error fetching harbor-cookie:', error)
      }
    }

    if (isPrivatePage && isClient && !isSignupViewed) {
      const [visitorId, prospectId, viewCount, sfContactId, sfOwnerId] =
        harborCookie?.split('|') ?? []

      if (
        !prospectId ||
        !sfContactId ||
        !sfOwnerId ||
        prospectId === 'null' ||
        sfContactId === 'null' ||
        sfOwnerId === 'null'
      ) {
        if (!skipLoginPaths.includes(pathname)) {
          fetchHarborCookie()
        }
      }
    }
  }, [
    pathname,
    isPrivatePage,
    isSignupViewed,
    harborCookie,
    authCtx.actions,
    startTransition,
    isClient,
  ])

  const handleCloseIframe = () => {
    startTransition(() => {
      setIsOpenIframe(false)
    })
  }

  const shouldShowAttestationModal = useMemo(() => {
    return (
      !isInsight &&
      isPrivatePage &&
      !authCtx?.state?.isLoggedIn &&
      authCtx?.state.userExperience !== USER_EXPERIENCES.INSTITUTIONAL_ONLY
    )
  }, [
    isInsight,
    isPrivatePage,
    authCtx?.state?.isLoggedIn,
    authCtx?.state.userExperience,
  ])

  const handleAttestationClose = () => {
    navigate('/')
  }

  return (
    <>
      {isClient && openIframe && (
        <IframePopup
          src="https://forms.harborcapital.com/f/signup"
          isOpen={openIframe}
          onClose={handleCloseIframe}
        />
      )}
      <AttestationModal
        open={shouldShowAttestationModal}
        onCloseCancel={handleAttestationClose}
      />

      {children}
      {isClient && isModalOpen && formId && formCta && (
        <FormModal
          onClose={handleModalClose}
          isOpen={isModalOpen}
          formId={formId}
          customValidation={customValidation}
          isModal
          ctaText={formCta}
          fromLocation={fromLocation}
        />
      )}
      {isPending && <div>Updating Modal...</div>}
    </>
  )
}

const withLocation = ({ children }) => {
  return (
    <Location>
      {(location) => (
        <GatedContentHandler router={location}>{children}</GatedContentHandler>
      )}
    </Location>
  )
}

export default React.memo(withLocation)
