import React, { useState } from 'react'
import CookieConsent from 'react-cookie-consent'
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import cx from 'classnames'

import Html from '../Html/Html'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'row-reverse',
    marginBottom: '15px',
  },
  htmlContainer: {
    fontSize: '14px',
    '& a': {
      color: '#D8B45A',
    },
  },
  disclaimerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  disclaimerTitle: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    margin: '8px 0',
  },
  closeDisclaimer: {
    fontSize: '18px',
    color: '#D8B45A',
  },
}))

export default function CookieConsentPopUp(props) {
  const [isClosed, disclose] = useState(false)

  const closePopUp = () => disclose(true)

  const classes = useStyles()

  if (isClosed) return null

  return (
    <CookieConsent
      location="bottom"
      buttonText="I agree"
      enableDeclineButton
      declineButtonText="I decline"
      cookieName={props.title}
      contentStyle={{ flex: 'none' }}
      style={{ background: '#092A48', flexDirection: 'column', zIndex: 10002 }}
      buttonStyle={{
        color: '#4e503b',
        fontSize: '16px',
        backgroundColor: '#D8B45A',
        borderRadius: '24px',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'center',
        padding: '8px 30px',
        fontFamily: 'Poppins',
        fontWeight: '600',
        margin: '0 15px',
      }}
      declineButtonStyle={{
        color: '#4e503b',
        fontSize: '16px',
        borderRadius: '24px',
        padding: '8px 30px',
        backgroundColor: '#D8B45A',
        fontFamily: 'Poppins',
        fontWeight: '600',
        margin: '0 15px',
      }}
      buttonWrapperClasses={cx(classes.root)}
      expires={150}
    >
      <div className={cx(classes.disclaimerContainer)}>
        <h2 className={cx(classes.disclaimerTitle)}>We Use Cookies</h2>
        <div className={cx(classes.closeDisclaimer)} onClick={closePopUp}>
          <CloseIcon style={{ fontSize: 20 }} />
        </div>
      </div>
      <Html content={props.html} className={cx(classes.htmlContainer)} />
    </CookieConsent>
  )
}

export function CookieConsentPopUpWrapper() {
  const data = useStaticQuery(graphql`
    query {
      contentstackCookieDisclaimer {
        id
        title
        html
      }
    }
  `)

  return (
    <CookieConsentPopUp
      html={data.contentstackCookieDisclaimer.html}
      title={data.contentstackCookieDisclaimer.title}
    />
  )
}
