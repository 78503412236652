import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust based on form container height */
`

const SpinnerElement = styled.div`
  border: 6px solid #f3f3f3; /* Light gray */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${rotate} 1s linear infinite;
`

const Spinner = () => (
  <SpinnerWrapper>
    <SpinnerElement />
  </SpinnerWrapper>
)

export default Spinner
