import React, { useState } from 'react'
import Modal from '@material-ui/core/Modal'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useMediaQuery } from '@material-ui/core'
import { Stack } from '../common/layouts/Stack'
import { Box } from '../common/layouts/Box'
import * as Styles from './styles'
import { CategoriesTab } from './CategoriesTab'
import { AuthorsTab } from './authorsTab'
import { ProductsTab } from './productsTab'

export const FILTER_CATEGORIES = {
  AUTHOR: 'Author',
  CATEGORY: 'Category',
  PRODUCT: 'Product',
}

export const InsightsFiltersModal = ({
  onClose,
  selectedId,
  initialSelectedFilterCategory,
}) => {
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(
    initialSelectedFilterCategory
  )

  const isMobile = useMediaQuery('(max-width: 503px)')

  const handleTabClick = (tab) => () => {
    setSelectedFilterCategory(tab)
  }

  return (
    <Modal open onClose={onClose}>
      <Styles.Root>
        <Stack justifyContent="space-between" alignItems="center">
          <Stack columnGap="2px">
            {Object.values(FILTER_CATEGORIES).map((filterCategory) => (
              <Styles.Tab
                key={filterCategory}
                onClick={handleTabClick(filterCategory)}
                selected={selectedFilterCategory === filterCategory}
                filterCategory={filterCategory}
              >
                {filterCategory}
              </Styles.Tab>
            ))}
          </Stack>
          <Styles.ResetFilterContainer>
            {!isMobile && (
              <Styles.ResetFilter to="/insights" onClick={onClose}>
                <Stack alignItems="center" columnGap="7px">
                  reset <RefreshIcon size={16} />
                </Stack>
              </Styles.ResetFilter>
            )}
            <Styles.StyledCloseIcon onClick={onClose} />
          </Styles.ResetFilterContainer>
        </Stack>
        {isMobile && (
          <Styles.ResetFilter to="/insights" onClick={onClose}>
            <Stack alignItems="center" columnGap="7px">
              reset filter <RefreshIcon size={16} />
            </Stack>
          </Styles.ResetFilter>
        )}
        <Styles.Content>
          <Box overflowY="auto">
            {selectedFilterCategory === FILTER_CATEGORIES.CATEGORY && (
              <CategoriesTab onClose={onClose} selectedId={selectedId} />
            )}
            {selectedFilterCategory === FILTER_CATEGORIES.AUTHOR && (
              <AuthorsTab onClose={onClose} selectedId={selectedId} />
            )}
            {selectedFilterCategory === FILTER_CATEGORIES.PRODUCT && (
              <ProductsTab onClose={onClose} selectedId={selectedId} />
            )}
          </Box>
          <Styles.Caption>
            Select a filter option using the Author, Category, and Product tabs
          </Styles.Caption>
        </Styles.Content>
      </Styles.Root>
    </Modal>
  )
}
