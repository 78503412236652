import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { BLUE_BASE, BLUE_LIGHT, WHITE } from '../../../theme/variables/colors'

export const Link = styled(GatsbyLink)`
  text-decoration: none;
  color: ${({ selected }) => (selected ? WHITE : BLUE_BASE)};
  background-color: ${({ selected }) => (selected ? BLUE_BASE : BLUE_LIGHT)};
  width: 100%;
  display: flex;
  padding: 10px 20px;
  border-radius: 8px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  align-items: center;
`

export const NumberOfArticles = styled.span`
  font-weight: 400;
`
