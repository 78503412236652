import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Stack } from '../../common/layouts/Stack'
import * as Styles from './styles'
import { useScrollIntoView } from '../../../utils/hooks'

export const AuthorsTab = ({ onClose, selectedId }) => {
  const insights = useStaticQuery(graphql`
    query {
      allContentstackPeople(sort: { title: ASC }) {
        nodes {
          id
          title
          url
          job_title
          company
          product_subadvisers {
            subadviser_firm {
              title
            }
          }
          default_photo {
            localAsset {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      allContentstackInsightArticle {
        group(field: { people: { id: SELECT } }) {
          fieldValue
          totalCount
        }
      }
    }
  `)

  const { elementRef } = useScrollIntoView()

  const peopleWithNumInsights = useMemo(() =>
    insights.allContentstackPeople?.nodes
      ?.map(
        ({
          id,
          title,
          url,
          default_photo,
          job_title,
          company,
          product_subadvisers,
        }) => ({
          id,
          title,
          url: `/insights/author${url}`,
          selected: id === selectedId,
          photo: default_photo?.localAsset,
          description: job_title,
          company:
            company ?? product_subadvisers?.[0]?.subadviser_firm?.[0]?.title,
          numInsights: insights.allContentstackInsightArticle.group?.find(
            (group) => group?.fieldValue === id
          )?.totalCount,
        })
      )
      .filter((insight) => insight.numInsights > 0)
  )

  return (
    <Grid container spacing={2} style={{ margin: 0, width: '100%' }}>
      {peopleWithNumInsights.map(
        (
          { title, numInsights, url, selected, photo, description, company },
          index
        ) => (
          <Grid item key={index} xs={12} sm={6}>
            <Styles.Link
              to={url}
              onClick={onClose}
              selected={selected}
              ref={selected ? elementRef : undefined}
            >
              <Styles.Avatar>
                <GatsbyImage
                  image={getImage(photo)}
                  imgStyle={{
                    borderRadius: '50%',
                  }}
                  style={{
                    height: '100%',
                    width: '100%',
                  }}
                />
              </Styles.Avatar>
              <Stack flexDirection="column">
                <Stack columnGap="10px">
                  {title}
                  <Styles.NumberOfArticles>
                    [{numInsights}]
                  </Styles.NumberOfArticles>
                </Stack>
                <Styles.Description>{description}</Styles.Description>
                <Styles.Description>{company}</Styles.Description>
              </Stack>
            </Styles.Link>
          </Grid>
        )
      )}
    </Grid>
  )
}
