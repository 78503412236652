import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import {
  HarborModal,
  S as HarborModalStyles,
} from '../common/modal/HarborModal'
import Html from '../Html/Html'

export const AttestationModal = styled(HarborModal)`
  color: #092a48;

  ${HarborModalStyles.Body} {
    max-height: 90vh;
    max-width: 80vw;
    display: flex;
    flex-direction: column;

    @media (max-width: 960px) {
      max-height: 85vh;
      margin-top: 10vh;
    }

    @media (max-width: 767px) {
      font-size: 12px;
      max-height: 85vh;
      margin-top: 10vh;
    }
  }

  ${HarborModalStyles.Content} {
    padding-bottom: 2rem;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }
`

export const Title = styled(Typography)`
  text-align: center;
`

export const BodyRoot = styled.div`
  overflow: scroll;
  text-align: justify;
`

export const AttestationModalFooterButtonsRoot = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
`

export const FooterHtml = styled(Html)`
  @media (max-width: 767px) {
    font-size: 10px;
  }
`
