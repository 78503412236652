import React from 'react'
import PropTypes from 'prop-types'
import FormComponent from './FormComponent'
import ErrorBoundary from './ErrorBoundary'

const FormAssemblyForm = React.memo(
  ({ formId, customValidation, isModal, ctaText, fromLocation }) => (
    <ErrorBoundary>
      <FormComponent
        formId={formId}
        customValidation={customValidation}
        isModal={isModal}
        ctaText={ctaText}
        fromLocation={fromLocation}
      />
    </ErrorBoundary>
  )
)

FormAssemblyForm.propTypes = {
  formId: PropTypes.number.isRequired,
  customValidation: PropTypes.string,
  isModal: PropTypes.bool,
  ctaText: PropTypes.string,
  fromLocation: PropTypes.string,
}

export default FormAssemblyForm
