import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Button from '@material-ui/core/Button'
import Cookies from 'js-cookie'
import { useCookies } from 'react-cookie'

import * as Styles from './styles'
import Html from '../Html/Html'
import { AuthContext } from '../../utils/context/AuthContext'
import { USER_EXPERIENCES } from '../../utils/constants'

const AttestationModalFooter = ({ footerHtml, handleAccept, handleCancel }) => (
  <>
    <Styles.AttestationModalFooterButtonsRoot>
      <Button onClick={handleAccept}>Accept</Button>
      <Button onClick={handleCancel}>Cancel</Button>
    </Styles.AttestationModalFooterButtonsRoot>
    <Styles.FooterHtml content={footerHtml} />
  </>
)

export const AttestationModal = ({
  open,
  onCloseAccept = () => null,
  onCloseCancel = () => null,
}) => {
  const attestationData = useStaticQuery(graphql`
    query {
      contentstackInstitutionalUserAttestation {
        title
        html
        footer_html
      }
    }
  `)
  const [isOpen, setIsOpen] = useState(false)
  const [cookies] = useCookies(['acceptedAttestation'])
  const { acceptedAttestation } = cookies
  const auth = React.useContext(AuthContext)

  useEffect(() => {
    if (!acceptedAttestation || acceptedAttestation === 'false') {
      setIsOpen(open)
    }
  }, [open])

  if (!isOpen) return null

  const node = attestationData?.contentstackInstitutionalUserAttestation

  if (!node) return null

  const { title, html, footer_html } = node

  const handleAccept = () => {
    setIsOpen(false)

    auth.actions.setUserExperience(USER_EXPERIENCES.INSTITUTIONAL_ONLY)
    Cookies.set('acceptedAttestation', 'true', {
      expires: new Date('9999-12-31'),
    })

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'InstitutionalAttestation',
      })
    }

    onCloseAccept()
  }

  const handleCancel = () => {
    onCloseCancel()
  }

  return (
    <Styles.AttestationModal
      open
      onClose={handleCancel}
      buttonActions={
        <AttestationModalFooter
          footerHtml={footer_html}
          handleAccept={handleAccept}
          handleCancel={handleCancel}
        />
      }
    >
      <Styles.Title variant="h4">{title}</Styles.Title>
      <Styles.BodyRoot>
        <Html content={html} />
      </Styles.BodyRoot>
    </Styles.AttestationModal>
  )
}
