import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react'

export const SideNavigationContext = createContext()

export const useSideNavigationContext = () => {
  return useContext(SideNavigationContext)
}

const useInViewSectionsObservers = (onEnterView, options = {}) => {
  const sectionRefs = useRef([])

  useEffect(() => {
    const handleScroll = () => {
      let minDistanceToTop = Infinity
      let closestIndex = -1

      sectionRefs.current.forEach((sectionRef, index) => {
        const boundingBox = sectionRef.current?.getBoundingClientRect()
        if (boundingBox) {
          const distanceToTop = Math.abs(boundingBox.top)

          if (distanceToTop < minDistanceToTop) {
            minDistanceToTop = distanceToTop
            closestIndex = index
          }
        }
      })

      if (closestIndex !== -1) {
        onEnterView(closestIndex)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [onEnterView, options])

  // Create a ref for each section
  const createSectionRef = (index) => {
    sectionRefs.current[index] = useRef(null)
    return sectionRefs.current[index]
  }

  return { createSectionRef }
}

const SideNavigationContextProvider = ({ children }) => {
  const [highlightedNavItem, setHighlightedNavItem] = useState(0)

  const { createSectionRef } = useInViewSectionsObservers(setHighlightedNavItem)

  return (
    <SideNavigationContext.Provider
      value={{ highlightedNavItem, setHighlightedNavItem, createSectionRef }}
    >
      {children}
    </SideNavigationContext.Provider>
  )
}

export default SideNavigationContextProvider
