import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { PRIMARY_COLOR_MAPPING, SECONDARY_COLOR_MAPPING } from '../constants'

const HarborThemeWrapper = styled.div`
  & .withHarborThemeCta {
    background-color: ${({ ctaPrimaryLinkColor }) => ctaPrimaryLinkColor};
    color: ${({ ctaSecondaryLinkColor }) => ctaSecondaryLinkColor};
  }

  & .withHarborThemeWrapper a {
    color: ${({ linkColor }) => linkColor};
    text-decoration: none;
  }
`

export const HarborCsThemeProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      contentstackTheme {
        default_link_color {
          link_color
        }
        default_cta_color {
          cta_color
        }
      }
    }
  `)

  const theme = useMemo(
    () => ({
      linkColor:
        PRIMARY_COLOR_MAPPING[data.contentstackTheme?.default_link_color?.link_color],
      ctaPrimaryLinkColor:
        PRIMARY_COLOR_MAPPING[data.contentstackTheme?.default_cta_color?.cta_color],
      ctaSecondaryLinkColor:
        SECONDARY_COLOR_MAPPING[
          data.contentstackTheme?.default_cta_color?.cta_color
        ],
    }),
    [data]
  )

  return (
    <HarborThemeWrapper
      linkColor={theme.linkColor}
      ctaPrimaryLinkColor={theme.ctaPrimaryLinkColor}
      ctaSecondaryLinkColor={theme.ctaSecondaryLinkColor}
    >
      {children}
    </HarborThemeWrapper>
  )
}
