import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { navigate } from 'gatsby'

const TokenContext = createContext({
  isAuthVerified: false,
  csrfToken: null,
  verifyMagicLinkToken: async () => false,
  verifyAuthToken: async () => false,
  logout: async () => {},
  loading: false,
  error: null,
})

export const useToken = () => useContext(TokenContext)

export const TokenProvider = ({ children }) => {
  const [isAuthVerified, setIsAuthVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [csrfToken, setCsrfToken] = useState('')

  const fetchCsrfToken = useCallback(async () => {
    try {
      const response = await fetch('/api/csrf-token', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const data = await response.json()
      setCsrfToken(data.token)
      return data.token
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch CSRF token:', err)
      setError('Failed to fetch CSRF token.')
      return null
    }
  }, [])

  const makePostRequest = useCallback(
    async (url, body) => {
      setLoading(true)
      try {
        if (!csrfToken) {
          throw new Error('CSRF token is missing.')
        }

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-csrf-token': csrfToken,
          },
          body: JSON.stringify(body),
          credentials: 'include',
        })

        if (!response.ok) {
          const errorData = await response.json()
          throw new Error(errorData.error || 'Request failed.')
        }

        return await response.json()
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        setError(err.message || 'An error occurred during the request.')
        return null
      } finally {
        setLoading(false)
      }
    },
    [csrfToken]
  )

  const verifyMagicLinkToken = useCallback(
    async (token) => {
      try {
        const data = await makePostRequest('/api/auth-verify-magic-token', {
          token,
        })

        if (data.firstUse) {
          setIsAuthVerified(true)
          return { firstUse: true }
        }

        return {}
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        setError(
          err.message || 'An error occurred during magic link verification.'
        )
        return false
      }
    },
    [makePostRequest]
  )

  const verifyAuthToken = useCallback(async () => {
    try {
      const data = await makePostRequest('/api/auth-verify-token')
      if (data) {
        setIsAuthVerified(true)
        return true
      }
      setIsAuthVerified(false)
      return false
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      setError(
        err.message || 'An error occurred during auth token verification.'
      )
      setIsAuthVerified(false)
      return false
    }
  }, [makePostRequest])

  const logout = useCallback(async () => {
    try {
      const data = await makePostRequest('/api/auth-logout', {})
      if (data) {
        setIsAuthVerified(false)
        navigate('/login-form', { replace: true })
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
      setError('Logout failed.')
    }
  }, [makePostRequest])

  useEffect(() => {
    if (!csrfToken) {
      fetchCsrfToken()
    }
  }, [csrfToken, fetchCsrfToken])

  const contextValue = {
    isAuthVerified,
    csrfToken,
    verifyAuthToken,
    verifyMagicLinkToken,
    logout,
    loading,
    error,
  }

  return (
    <TokenContext.Provider value={contextValue}>
      {children}
    </TokenContext.Provider>
  )
}
