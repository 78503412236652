/* eslint-disable react/no-danger */
import React, { forwardRef } from 'react'
import { Typography } from '@material-ui/core'
import { parse } from 'node-html-parser'
import cx from 'classnames'

// Function to add ids to all h2s based on their title, to support marketing directly linking to section
function parsedHtml(content) {
  try {
    const parsedContent = parse(content)
    const allH2s = parsedContent?.querySelectorAll('h2')
    if (allH2s && allH2s.length > 0) {
      allH2s.forEach((h2) => {
        const node_title = h2.innerHTML
          ?.replace(/<[^>]+>/g, '')
          .replace(/\s/g, '_')
          .toLowerCase()
        if (!h2.id) {
          // eslint-disable-next-line no-param-reassign
          h2.id = node_title
          const starter = 'id="'
          // eslint-disable-next-line no-param-reassign
          h2.rawAttrs = starter.concat(node_title, '"')
        }
      })
    }
    return parsedContent.toString()
  } catch {
    return content
  }
}

const HtmlComponent = (
  {
    className,
    title,
    content,
    ContentWrapper = ({ children }) => <>{children}</>,
  },
  ref
) => (
  <>
    {title && (
      <Typography variant="h2" style={{ textAlign: 'center' }}>
        {title}
      </Typography>
    )}
    {content && (
      <ContentWrapper>
        <div
          key={new Date().getTime()}
          ref={ref}
          className={cx('withHarborThemeWrapper', className)}
          dangerouslySetInnerHTML={{ __html: parsedHtml(content) }}
        />
      </ContentWrapper>
    )}
  </>
)

const Html = forwardRef(HtmlComponent)
export default Html
