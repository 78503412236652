import { useRef, useEffect, useState } from 'react'

const useReduceFontSizeToFit = (baseFontSize, customHeight) => {
  const [fontSize, setFontSize] = useState(baseFontSize)

  const elementRef = useRef()

  const heightToCompare =
    customHeight ?? elementRef.current?.parentElement?.clientHeight

  useEffect(() => setFontSize(baseFontSize), [baseFontSize])

  useEffect(() => {
    if (
      elementRef.current &&
      fontSize > 10 &&
      (elementRef.current.clientHeight > heightToCompare ||
        fontSize > baseFontSize)
    ) {
      setFontSize(fontSize - 2)
    }
  }, [elementRef, fontSize, heightToCompare, baseFontSize])

  return { elementRef, fontSize }
}

export default useReduceFontSizeToFit
