import styled from 'styled-components'

export const Stack = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  margin-top: ${({ marginTop }) => marginTop};
  column-gap: ${({ columnGap }) => columnGap};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  border: ${({ border }) => border};
  padding: ${({ padding }) => padding};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  border-radius: ${({ borderRadius }) => borderRadius};
  row-gap: ${({ rowGap }) => rowGap};
  padding-top: ${({ paddingTop }) => paddingTop};
  gap: ${({ gap }) => gap};
  margin-left: ${({ marginLeft }) => marginLeft};
  border-bottom: ${({ borderBottom }) => borderBottom};
  gap: ${({ gap }) => gap};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex: ${({ flex }) => flex};
`
