import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { navigate } from 'gatsby'
import { useCookies } from 'react-cookie'
import { USER_EXPERIENCES } from '../constants'
import { useToken } from './TokenContext'

export const USER_KEY = 'USER_INFO'

export const AuthContext = React.createContext({})

export const useAuthContext = () => {
  return React.useContext(AuthContext)
}

const defaultUser = {}

function parseUserObject(userToParse, leadToParse, contactToParse) {
  const {
    Contact_Owner_Email__c,
    Contact_Owner_Full_Name__c,
    Contact_Owner_Phone__c,
    Contact_Owner_Photo_Link__c,
    Contact_Owner_Calendar__c,
    ContactId,
    Email,
    UserType,
    IsActive,
  } = userToParse

  return {
    name: leadToParse?.Name?.value ?? contactToParse?.Name?.value,
    Id: leadToParse?.Id ?? contactToParse?.Id,
    contactEmail: Contact_Owner_Email__c?.value,
    contactFullName: Contact_Owner_Full_Name__c?.value,
    contactPhone: Contact_Owner_Phone__c?.value,
    contactPhotoLink: Contact_Owner_Photo_Link__c?.value?.replaceAll(
      '&amp;',
      '&'
    ),
    contactOwnerCalendarLink: Contact_Owner_Calendar__c?.value,
    contactId: ContactId?.value,
    ownerEmail: Email?.value,
    ownerUserType: UserType?.value,
    ownerIsActive: IsActive?.value,
    isContact: Boolean(contactToParse),
  }
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(defaultUser)
  const [userLoaded, setUserLoaded] = useState(false)
  const [userExperience, setUserExperience] = useState(
    USER_EXPERIENCES.UNATTESTED
  )
  const [cookies] = useCookies(['acceptedAttestation', 'harborCookie'])
  const { harborCookie } = cookies

  const {
    csrfToken,
    logout: tokenLogout,
    loading: tokenLoading,
    error: tokenError,
  } = useToken()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const tryLogin = useCallback(async () => {
    if (typeof window === 'undefined') {
      return
    }

    const userData = JSON.parse(window.sessionStorage.getItem('USER_DATA'))

    if (userData) {
      setUser(userData)
      setUserLoaded(true)
      setLoading(false)
      return
    }

    if (harborCookie) {
      const [sfContactId, sfOwnerId] = harborCookie?.split('|').slice(3) ?? []

      if (
        !!sfContactId &&
        !!sfOwnerId &&
        sfContactId !== 'null' &&
        sfOwnerId !== 'null'
      ) {
        try {
          const res = await fetch('/api/get-user', {
            headers: {
              'Content-Type': 'application/json',
              'x-csrf-token': csrfToken,
            },
            credentials: 'same-origin',
          })

          if (!res.ok) {
            throw new Error('Failed to fetch user data.')
          }

          const data = await res.json()

          const userObjectResponse =
            data?.user?.data?.uiapi?.query?.User?.edges?.[0]?.node
          const userLeadResponse =
            data?.lead?.data?.uiapi?.query?.Lead?.edges?.[0]?.node
          const userContactResponse =
            data?.contact?.data?.uiapi?.query?.Contact?.edges?.[0]?.node

          if (userObjectResponse && (userLeadResponse || userContactResponse)) {
            const parsedUserObject = parseUserObject(
              userObjectResponse,
              userLeadResponse,
              userContactResponse
            )
            setUser(parsedUserObject)
            window.sessionStorage.setItem(
              'USER_DATA',
              JSON.stringify(parsedUserObject)
            )
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Failed to fetch user data:', err)
          window.sessionStorage.setItem('USER_DATA', JSON.stringify({}))
        } finally {
          setUserLoaded(true)
          setLoading(false)
        }
      } else {
        setUserLoaded(true)
        setLoading(false)
      }
    } else {
      setUserLoaded(true)
      setLoading(false)
    }
  }, [harborCookie, csrfToken])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      tryLogin()
    }
  }, [tryLogin])

  const isLoggedIn = useMemo(
    () =>
      Boolean(user?.contactId) &&
      user.ownerIsActive &&
      user.ownerUserType === 'Standard' &&
      user.ownerEmail[user]
  )

  useEffect(() => {
    let newUserExperience = USER_EXPERIENCES.UNATTESTED

    if (cookies.acceptedAttestation === 'true') {
      newUserExperience = USER_EXPERIENCES.INSTITUTIONAL_ONLY
    } else if (cookies.acceptedAttestation === 'false') {
      newUserExperience = USER_EXPERIENCES.RETAIL_ONLY
    }

    setUserExperience(newUserExperience)
  }, [isLoggedIn, cookies.acceptedAttestation])

  const handleLogout = useCallback(async () => {
    await tokenLogout() // Use logout from TokenContext
    setUser(defaultUser)
    window.sessionStorage.removeItem('USER_DATA')
    navigate('/login-form', { replace: true })
  }, [tokenLogout])

  const value = {
    state: {
      user,
      isLoggedIn,
      userExperience,
      userLoaded,
      loading: loading || tokenLoading,
      error: error || tokenError,
    },
    actions: {
      tryLogin,
      setUser,
      setUserExperience,
      logout: handleLogout,
    },
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthContext
