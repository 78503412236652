import { useRef, useEffect } from 'react'

export const useScrollIntoView = () => {
  const elementRef = useRef(null)

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  return { elementRef }
}
