import React from 'react'

export const PointyChevron = (props) => (
  <svg
    width="14"
    height="17"
    viewBox="0 0 14 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_16967_2797)">
      <path d="M6.12435 0.499999L0.666667 0.499999L8.57686 8.5L0.666666 16.5L6.12435 16.5L14 8.5L6.12435 0.499999Z" />
    </g>
    <defs>
      <clipPath id="clip0_16967_2797">
        <rect
          width="13.3333"
          height="16"
          fill="white"
          transform="translate(14 16.5) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
)
