import styled from 'styled-components'
import { hexToRgba } from '../../utils/functions'
import { BLUE_BASE } from '../../theme/variables/colors'

const navyColor = hexToRgba(BLUE_BASE, 0.8)

export const OuterModal = styled.div`
  margin: 0 auto;
  max-width: 80%;
  min-width: 40%;
  padding: 20px;
  position: fixed;
  z-index: 9999;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255);
  box-shadow: 0 4px 8px ${navyColor};
  border-radius: 20px;
  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 100vh;
    height: 100%;
    width: 100%;
  }
`
export const HeaderModal = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
`

export const BodyModal = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background: rgb(255, 255, 255);
  max-height: 75vh;
  height: fit-content;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 90vh;
  }
`

export const CloseButton = styled.div`
  position: relative;
  width: 30px;
  color: ${navyColor};
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.4s ease-in-out;

  &.spin {
    transform: rotate(360deg);
  }
`

export const Line = styled.div`
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: black;
  transition: transform 0.4s ease-in-out;
`

export const LineBefore = styled(Line)`
  transform: rotate(45deg);
`

export const LineAfter = styled(Line)`
  transform: rotate(-45deg);
`
